import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="infinite-scroll"
export default class extends Controller {
  static targets = ["pagination", "entries"];

  initialize() {
    let options = {
      rootMargin: '0px 0px -200px 0px',
    }
    this.intersectionObserver = new IntersectionObserver(entries => this.processIntersectionEntries(entries), options)
  }

  connect() {
    console.log("hello");
  }

  processIntersectionEntries(entries) {
    console.log(entries);
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        console.log(entry);
        this.loadMore()
      }
    })
  }

  loadMore() {
    let next_page = this.paginationTarget.getById("next_link")
    if (next_page == null) { return }
    let url = next_page.href
    console.log(url);
    // Rails.ajax({
    //   type: 'GET',
    //   url: url,
    //   dataType: 'json',
    //   success: (data) => {
    //     this.entriesTarget.insertAdjacentHTML('beforeend', data.entries)
    //     this.paginationTarget.innerHTML = data.pagination
    //   }
    // })
  }

  disconnect() {
    this.intersectionObserver.unobserve(this.paginationTarget)
  }
}
