import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap";
// Connects to data-controller="figurine"
export default class extends Controller {
  static targets = ["form"]
  connect() {
    const modalElement = this.element.querySelector(".modal")
    this.modal = Modal.getOrCreateInstance(modalElement)
  }

  delete(e) {
    e.preventDefault()
    const form = e.currentTarget
    const url = form.action
    fetch(url, {
      method: 'DELETE',
      headers: {
        // 'Content-Type': 'application/json'
      },
      body: new FormData(form)

    })
      .then(response => response.json())
      .then(data => {
        if (!data.error) {
          this.modal.hide()
          this.element.remove()
        } else {
          this.displayAlert(data.message)
        }
      })
      .catch(error => console.log(error));
  }

  displayAlert(message) {
    const html = `<div class="alert alert-danger">${message}</div>`
    this.modal._dialog.querySelector(".modal-body").innerHTML = html
  }
}
