import { Controller } from "@hotwired/stimulus"
import Muuri from "muuri"

// Connects to data-controller="gallery"
export default class extends Controller {
  static targets = [ "search", "filter", "tagInput", "seeAllInput", "grid" ]
  connect() {
    const options = {
      showDuration: 300,
      layout: {
        fillGaps: true
      }
    }
    this.grid = new Muuri(this.gridTarget, options);
  }

  refreshItems(e) {
      this.grid.refreshItems().layout();
      // For a little finishing touch, let's fade in
      // the images after all them have loaded and
      // they are corrertly positioned.
      document.body.classList.add('images-loaded');
      const items = this.grid.getItems()
      items.forEach((item) => item.getElement().classList.add('show'))
  }

  filter(event) {
    event.preventDefault()
    const filterFields = this.filterTarget.querySelectorAll(':checked')
    const filterFieldValues = Array.from(filterFields).map(input => input.name)

    this.grid.filter((item) => {
      const element = item.getElement();
      return this.#isSearchMatch(element) && this.#isFilterMatch(element, filterFieldValues)
    });
  }

  #isSearchMatch(element) {
    const searchFieldValue = this.#normalize(this.searchTarget.value)
    return this.#normalize(element.querySelector('img').getAttribute('title')).includes(searchFieldValue) ||
           this.#normalize(element.querySelector('img').getAttribute('alt')).includes(searchFieldValue)
  }

  #normalize(str) {
    return str.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase()
  }

  #isFilterMatch(element, filterFieldValues) {
    if (filterFieldValues.includes("*")) { return true }

    return filterFieldValues.every(tag => element.classList.contains(tag))
  }

  toogleTagsInputs(event) {
    if (event.target.checked === true) {
      const inputAllChecked = this.tagInputTargets;
      inputAllChecked.forEach(inputChecked => inputChecked.checked = false)
     }
  }

  toogleSeeAllInputs(event) {
    if (event.target.checked === true) {
      const inputAnyChecked = this.seeAllInputTarget;
      inputAnyChecked.checked = false
     }
  }
}
