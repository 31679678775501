import { Controller } from "@hotwired/stimulus"
import Mustache from "mustache";
import { Tab } from 'bootstrap'

// Connects to data-controller="simulator"
export default class extends Controller {
  static targets = [
    'category',
    'subCategory',
    'form',
    'tagsTemplate',
    'tagsSection',
    'productSelectorsTemplate',
    'productsGrid',
    'preview',
    'navigation',
    'spinner',
    'brickInput',
    'formsWrapper',
    'figurines',
    'edifFigurineButtonWrapper',
    'editFigurineForms'
    // 'variantsSelect',
  ]

  static values = {
    figurine: Object,
    mainCategory: String,
    subCategory: String,
    initialUrl: String
  }

  initialize () {
    this.productsData = {}
  }

  connect() {
    this.#initActiveCatAndSubcat()
    this.#setActiveForm()
    this.#setFigurineObjectAndDisplayPreview()
    this.#fetchProducts(this.initialUrlValue).then(data => {
      this.productsData[data.permalink] = data.results
      if (this.activeSubCategory !== data.meta_title) { return }

      this.#displayProductsGridAndTags(this.productsData[data.permalink])
    })
  }

  emptyAccessories(e) {
    const metaTitles = JSON.parse(e.currentTarget.dataset.metatitles)
    this.figurine[metaTitles.main_category_meta_title][metaTitles.sub_category_meta_title] = []
    const figurineVariantIds = Object.values(this.figurine[metaTitles.main_category_meta_title]).flat().filter(i => i !== null)
    const form = this.formTargets.find(form => form.dataset.category === metaTitles.main_category_meta_title)
    const input = form.querySelector("[data-simulator-target='variantsSelect']")
    input.value = JSON.stringify(figurineVariantIds)
    this.#fetchAndDisplayPreview(figurineVariantIds)
    this.#setCheckedInputs()
  }

  #initActiveCatAndSubcat() {
    this.activeMainCategory = this.mainCategoryValue
    this.activeSubCategory = this.subCategoryValue
  }

  #setFigurineObjectAndDisplayPreview() {
    this.figurine = {...JSON.parse(this.formTarget.dataset.figurineObject)}
    const activeFigurineValues = Object.values(this.figurine[this.activeMainCategory]).flat().filter(i => i !== null)
    this.#fetchAndDisplayPreview(activeFigurineValues)
  }

  #setActiveForm() {
    this.activeForm = this.formTargets.find(form => form.dataset.category === this.activeMainCategory)
    this.formTargets.forEach(form => form === this.activeForm ? form.classList.remove("d-none") : form.classList.add("d-none"))
  }

  #showSpinner() {
    this.spinnerTarget.classList.remove("d-none");
  }

  #hideSpinner() {
    this.spinnerTarget.classList.add("d-none");
  }

  #handleProductsFetchErrors(url) {
    this.productsGridTarget.innerHTML = `<div class="bricks-grid--error alert alert-secondary">
      <p>Oups! Une erreur est survenue, merci de réessayer. Si le problème persiste, merci de nous contacter.</p>
      <div class="text-center">
        <button class="btn btn-outline-dark" type="button" data-action="simulator#retryFetch" data-url="${url}">Recharger la liste</button>
      </div>
    </div>`
  }

  retryFetch(event) {
    const url = event.target.dataset.url
    this.#fetchProducts(url)
  }

  async #fetchProducts(url) {
    this.#showSpinner()
    const options = {
      method: 'GET',
      header: {
        'Accept': 'application/json',
        'Content-type': 'application/json'
      }
    }

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      return data;
    } catch(e) {
      console.log(e);
      this.#handleProductsFetchErrors(url)
    } finally {
      this.#hideSpinner();
    }
  }

  #displayProductsGridAndTags(data) {
    this.#displayTags(data)
    this.#displayProductsGrid(data)
    this.#setCheckedInputs()
  }

  #displayTags(data) {
    const template = this.tagsTemplateTarget.innerHTML;
    const output = Mustache.render(template, data);
    this.tagsSectionTarget.innerHTML = output;
  }

  #displayProductsGrid(data) {
    const template = this.productSelectorsTemplateTarget.innerHTML;
    const output = Mustache.render(template, data);
    this.productsGridTarget.innerHTML = output;
  }

  #setCheckedInputs() {
    const variantInput = this.activeForm.querySelector("[data-simulator-target='variantsSelect']")
    const ids = JSON.parse(variantInput.value)
    this.brickInputTargets.forEach(input => input.checked = ids.includes(parseInt(input.value, 10)))
  }

  #updateFigurineObject(brick) {
    const mainCategory = brick.dataset.mainCategory
    const subCategory = brick.dataset.subCategory
    const variantId = parseInt(brick.value, 10)
    if (brick.type === "radio") {
      this.figurine[mainCategory][subCategory] = variantId
    } else {
      if (brick.checked) {
        this.figurine[mainCategory][subCategory].push(variantId)
      } else {
        this.#removeItemFromArray(this.figurine[mainCategory][subCategory], variantId)
      }
    }
  }

  #removeItemFromArray(array, item) {
    const index = array.indexOf(item)
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  #fetchAndDisplayPreview(values) {
    let url = '/figurines/preview'
    values.forEach((value, index) => {
      const prefix = index === 0 ? '?' : '&'
      url+= `${prefix}figurine[variant_ids][]=${value}`
    })
    fetch(url).then((response) => response.text())
              .then(data => this.previewTarget.innerHTML = data)
  }

  #enabledSubmitButton(form) {
    form.querySelector("input[type='submit']").removeAttribute("disabled")
  }

  #handleFigurineFormErrors(form, errors) {
    const errorsList = Object.entries(errors)
    errorsList.forEach((error) => {
      const formattedName = error[0].split(".").join("_")
      const input = form.querySelector(`.package_${formattedName} input`)
      input.classList.add('is-invalid')
      const invalidFeedbackHTML = ` <div class="invalid-feedback">
      ${error[1].join(", ")}
    </div>`
      input.insertAdjacentHTML('afterend', invalidFeedbackHTML)
    })
    this.#enabledSubmitButton(form)
  }

  selectBrick(e) {
    const brickInput = e.currentTarget
    this.#updateFigurineObject(brickInput)
    const input = this.activeForm.querySelector("[data-simulator-target='variantsSelect']")
    const values = Object.values(this.figurine[this.activeMainCategory]).flat().filter(i => i !== null)
    input.value = JSON.stringify(values)
    this.#fetchAndDisplayPreview(values)
  }

  #toggleSubCategoryActiveClass(tab) {
    this.subCategoryTargets.forEach(subCategory => {
      subCategory.dataset.permalink === tab.dataset.permalink ? subCategory.classList.add('active') : subCategory.classList.remove('active')
    })
  }

  filterByCategory(event) {
    const tab = event.target
    this.#setActiveForm()
    this.#toggleSubCategoryActiveClass(tab)
    this.#getProductsData(tab)
  }

  filterBySubCategory(event) {
    const tab = event.currentTarget
    this.#toggleSubCategoryActiveClass(tab)
    this.#getProductsData(tab)
  }

  #getProductsData(tab) {
    this.activeMainCategory = tab.dataset.mainCategory
    this.activeSubCategory = tab.dataset.subCategory

    const url = tab.dataset.target
    const activePermalink = tab.dataset.permalink

    if (this.productsData[activePermalink]) {
      this.#displayProductsGridAndTags(this.productsData[activePermalink])
    } else {
      this.#fetchProducts(url).then(data => {
        this.productsData[data.permalink] = data.results
        if (this.activeSubCategory !== data.meta_title) { return }

        this.#displayProductsGridAndTags(this.productsData[data.permalink])
      })
    }
  }

  submitFigurineForm(e) {
    e.preventDefault()
    const form = e.currentTarget
    const options = {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
      },
      body: new FormData(form)
    }
    fetch(form.action, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.errors) {
          this.#handleFigurineFormErrors(form, data.errors)
        } else {
          this.figurinesTarget.innerHTML = data.figurines
          this.formsWrapperTarget.innerHTML = data.figurine_form
          this.activeMainCategory = this.mainCategoryValue
          this.activeSubCategory = this.subCategoryValue
          this.#setActiveForm()
          this.#setFigurineObjectAndDisplayPreview()
          this.#setCheckedInputs()
        }
    })
  }

  editFigurine(e) {
    const wrapper = e.target.closest('[data-simulator-target="edifFigurineButtonWrapper"]')
    const template = wrapper.querySelector("template")
    const clone = template.content.cloneNode(true);
    this.formsWrapperTarget.innerHTML = ""
    this.formsWrapperTarget.append(clone)
    const figurineCategory = e.target.dataset.mainCategory
    this.activeMainCategory = figurineCategory
    const tab = this.categoryTargets.find(tab => tab.dataset.mainCategory == figurineCategory)
    const tabTrigger = new Tab(tab)
    tabTrigger.show()

    this.#toggleSubCategoryActiveClass(tab)
    this.#setFigurineObjectAndDisplayPreview()
    this.#setActiveForm()
    this.#getProductsData(tab)
    this.#setCheckedInputs()
  }
}
