import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="service-points"
export default class extends Controller {
  static targets = ['servicePointInput', 'postNumberInput']
  static values = {
    apiKey: String,
    country: String,
    postalCode: String,
    city: String,
    carriers: String
  }
  connect() {
    // this.openModal()
  }

  openModal() {
    const options = {
      apiKey: this.apiKeyValue,
      country: this.countryValue,
      postalCode: this.postalCodeValue,
      city: this.cityValue,
      carriers: this.carriersValue,
      // servicePointId: parseInt(servicePointIdField.value),
      // postNumber: postNumberField.value,
      language: "fr-fr"
      // shopType: shopTypeField.value,
    }
    sendcloud.servicePoints.open(options, this.successCallback.bind(this), this.failureCallback)
  }

  successCallback(servicePoint, postNumber) {
    // console.log({servicePoint, postNumber});
    this.servicePointInputTarget.value = servicePoint.id
    this.postNumberInputTarget.value = postNumber
  }

  failureCallback(errors) {
    console.error(errors);
  }
}
