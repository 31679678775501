import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.textContent = "Hello World!"
  }
}

// import { Controller } from "@hotwired/stimulus"

// export default class extends Controller {
//   static targets = [ "name", "output", "change" ]

//   greet() {
//     this.outputTarget.textContent =
//       `Hello, ${this.name}!`
//     this.changeTarget.textContent =
//       `Good job !`
//   }
//   get name() {
//     return this.nameTarget.value
//   }
// }
