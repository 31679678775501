import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="product-filter"
export default class extends Controller {
  static targets = ["checkbox", "product"]
  connect() {
  }

  filterProductsByTags(event) {
    const selectedTags = this.checkboxTargets.filter(input => input.checked).map(input => input.value);
    this.productTargets.forEach((product) => {
      const productTags = product.dataset.tags.split(',')
      const hasAllSelectedTags = selectedTags.every(tag => productTags.includes(tag));
      hasAllSelectedTags ? product.classList.remove('d-none') : product.classList.add('d-none')
    })
  }
}
