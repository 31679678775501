import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dynamic-modal"
export default class extends Controller {
  connect() {
  }

  loadContent(e) {
    const button = e.relatedTarget
    const newTitle = button.dataset.bsTitle
    const newContent = JSON.parse(button.dataset.bsContent).content
    const modalTitle = this.element.querySelector(".modal-title")
    const modalBody = this.element.querySelector(".modal-body")
    modalTitle.innerHTML = newTitle
    modalBody.innerHTML = newContent

    // To trigger solidus js events
    window.document.dispatchEvent(new Event("DOMContentLoaded", {
      bubbles: true,
      cancelable: true
    }));
  }
}
