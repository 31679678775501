import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="support-selector"
export default class extends Controller {
  static targets = [
    'input'
  ]

  connect() {
  }

  submit(e) {
    this.element.requestSubmit()
  }

  uncheckAll(e) {
    this.inputTargets.forEach((input) => input.checked = false)
    this.element.requestSubmit()

    window.location.href= e.target.dataset.nextStepPath;
  }

}
